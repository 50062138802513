import { Flex, Button, Box, Text } from '@chakra-ui/react'
import { FC } from 'react'

import { Image, HeartOutlineNestle, HeartFillNestle } from '@miimosa/design-system'

interface Props {
  imageUrl: string
  title: string
  description: string
  height?: number
  selected: boolean
  onOpen: () => void
  percent?: number
}

const LongProjectCard: FC<Props> = ({ description, onOpen, selected, imageUrl, title, percent, height = '260px' }) => {
  return (
    <Box position="relative">
      <Flex
        position="relative"
        minHeight={height}
        maxW="545px"
        direction="column"
        alignItems="flex-start"
        overflow="hidden"
        justifyContent="flex-end"
        textAlign="left"
        borderRadius="4px"
      >
        <Image src={imageUrl} fill style={{ objectFit: 'cover' }} alt="" quality={100} />
        <Box w="full" h="70%" position="absolute" bottom="0" left="0" bgGradient="linear(to-b, #0000, #000000cc)" />
        <Box zIndex="1" p={4} mb={5}>
          <Text textTransform="uppercase" size="xl" color={'white'}>
            {title}
          </Text>
          <Text mt={3} size="sm" color="white">
            {description}
          </Text>
        </Box>
      </Flex>
      <Flex position="absolute" zIndex={2} bottom="-24px" right="20px">
        {percent && (
          <Text color="brand" mt="auto" mr="3" fontWeight="bold">
            {Math.round(percent)}% des votes
          </Text>
        )}
        <Button
          variant="primary"
          size="md"
          leftIcon={selected ? <HeartFillNestle color="white" /> : <HeartOutlineNestle color="white" />}
          onClick={onOpen}
        >
          Je vote pour cette thématique
        </Button>
      </Flex>
    </Box>
  )
}

export default LongProjectCard
