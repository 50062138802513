import { SystemStyleObject } from '@chakra-ui/react'

import { tertiary, sizes } from './button'

export const Link: SystemStyleObject = {
  sizes: {
    sm: sizes.sm,
  },
  variants: {
    tertiary,
  },
  defaultProps: {
    size: '',
    variant: '',
    colorScheme: '',
  },
}
