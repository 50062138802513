import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'HowItWorksArrow',
  viewBox: '0 0 85 81',
  path: (
    <>
      <path
        d="M2 79.5C31.3333 73.5 85.6 49.8 68 3M68 3L61.5 18.5M68 3L83 10.5"
        stroke="#1F7A38"
        stroke-width="3"
        stroke-linecap="round"
        fill="none"
      />
    </>
  ),
})
