import '../styles/fonts.css'
import '../styles/globals.css'
import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/nextjs'
import type { AppProps } from 'next/app'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'rc-pagination/assets/index.css'
import { theme } from '../themes'

import { Layout } from '@components'

function FallbackComponent() {
  return <div>Une erreur est survenue</div>
}

// register your locale with timeago
// register('fr', timeagoFR)

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-WTZDZLH' })
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <ChakraProvider theme={theme}>
        <Layout>{<Component {...pageProps} height="100%" />}</Layout>
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  )
}

export default MyApp
