import { ComponentStyleConfig } from '@chakra-ui/react'

export const Container: ComponentStyleConfig = {
  variants: {
    boxed: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxW: { base: '100%', md: 'min(90%, 900px)' },
    },
    full: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxW: '100%',
      maxWidth: '100%',
      padding: 0,
    },
  },
}
