import { theme as baseTheme, extendTheme } from '@chakra-ui/react'
import localFont from 'next/font/local'

import { Button } from './button'
import { Container } from './container'
import { Heading } from './heading'
import Input from './input'
import { Link } from './link'
import { Text } from './text'

// Breakpoints
// https://chakra-ui.com/docs/features/responsive-styles
// Chakra breakpoints are:
// sm: "30em" = 480px
// md: "48em" = 768px
// lg: "62em" = 992px
// xl: "80em" = 1280px
// "2xl": "96em" = 1536px

const NestleText = localFont({ src: '../../public/fonts/NestleTextTF-Bold.ttf', display: 'swap' })

// Making sure chakra base sizes are not present
// eslint-disable-next-line @typescript-eslint/no-explicit-any
baseTheme.components.Heading.sizes = undefined as any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
baseTheme.components.Button.sizes = undefined as any

export const theme = extendTheme({
  components: {
    Heading,
    Text,
    Button,
    Container,
    Link,
    Input,
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      'html, body': {
        color: 'black',
      },
    },
  },
  shadows: {
    md: '0px 0px 16px rgba(0, 0, 0, 0.08)',
  },
  fonts: {
    heading: `${NestleText.style.fontFamily}, ${baseTheme.fonts.heading}`,
    body: `${NestleText.style.fontFamily}, ${baseTheme.fonts.body}`,
  },
  // Thes font size should not be used
  // Use the component's sizes instead
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
  lineHeights: {
    md: '1,4rem',
  },
  colors: {
    brand: '#0D461D',
    active: '#0D461D',
    hover: '#0D461D',
    disabled: '#E0E0E0',
    green: '#1F7A38',
    light_green: '#9DC41C',
    success: '#00B077',
    green_soft: '#F5F9EC',
    wheat: '#FEF3E2',
    white: '#fff',
    error: '#F64C46',
    miimosa: '#003F2C',
    black: '#000',
    radio: {
      500: '#1F7A38',
    },
    checkbox: {
      500: '#1F7A38',
    },
    input: {
      500: '#1F7A38',
    },
  },
})
