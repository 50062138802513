import { SystemStyleObject } from '@chakra-ui/react'

const sizes: Record<string, SystemStyleObject> = {
  sm: {
    fontSize: { base: '1.5rem' },
    fontWeight: { base: 400 },
    lineHeight: { base: '1.875rem' },
  },
  md: {
    fontSize: { base: '1.5rem' },
    fontWeight: { base: 700 },
    lineHeight: { base: '1.875rem' },
  },
  lg: {
    fontSize: { base: '2rem' },
    fontWeight: { base: 700 },
    lineHeight: { base: '2.25rem' },
  },
  xl: {
    fontSize: { base: '2.5rem' },
    fontWeight: { base: 700 },
    lineHeight: { base: '2.875rem' },
  },
  '2xl': {
    fontSize: { base: '2rem' },
    fontWeight: { base: 900 },
    fontFamily: 'NexaRustSansBlack',
    lineHeight: { base: '2.875rem' },
  },
}

export const Heading = {
  sizes,
  variants: {},
  // The default size and variant values
  defaultProps: {
    size: 'lg',
    color: 'black',
  },
}
