import { type BoxProps, AspectRatio } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Image } from '@miimosa/design-system'

const WheatRight = (props: BoxProps) => {
  return (
    <AspectRatio
      position="absolute"
      zIndex={-1}
      w="331px"
      ratio={1.23}
      display={{ base: 'none', md: 'block' }}
      right="0"
      {...props}
    >
      <Image alt="" src={s3ImageURL('wheat-right.png')} fill style={{ objectFit: 'cover' }} />
    </AspectRatio>
  )
}

export default WheatRight
