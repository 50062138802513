import Head from 'next/head'
import { ReactElement } from 'react'

import { s3ImageURL } from '@miimosa/common'

// import Footer from '../Footer'
// import Header from '../Header'

const Layout = ({ children }: { children: ReactElement }) => {
  return (
    <>
      <Head>
        <meta name="description" content="Nestle" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        <link rel="shortcut icon" href={s3ImageURL('favicon.ico')} />
        <link rel="icon" type="image/png" href={s3ImageURL('favicon.png')} sizes="64x64" />
      </Head>
      {/* <Header /> */}
      <main style={{ height: '100vh' }}>{children}</main>
      {/* <Footer /> */}
    </>
  )
}

export default Layout
