import { Button, Flex, Text, AspectRatio } from '@chakra-ui/react'

interface Props {
  video: string
  title: string
  description: string
  username: string
  href: string
}

const ProjectCard = ({ title, description, username, href, video, ...props }: Props) => {
  return (
    <Flex direction="column" flex="1 1 0%" {...props}>
      <Flex direction="column" flex="1 1 0%" border="1px solid" borderColor="black_text" borderRadius="4px">
        <AspectRatio w="100%" ratio={1.77}>
          <iframe
            src={video + '?badge=0&autopause=0&player_id=0&app_id=58479'}
            allow="autoplay; fullscreen; picture-in-picture"
            title={username}
          />
        </AspectRatio>
        <Flex direction="column" bgColor="white" px={6} py={5} flex="1 1 0%">
          <Text size="2xl" fontWeight="bold" height="56px" color="brand">
            {title}
          </Text>
          <Text size="lg" fontWeight="400" mt={2} flex="1 1 0%">
            {description}
          </Text>
          <Button variant="tertiary" mt={2} mb={6} as="a" href={href} target="_blank">
            En savoir plus sur le projet de {username}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ProjectCard
