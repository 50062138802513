import { SystemStyleObject } from '@chakra-ui/react'

const baseText = {
  fontSize: { base: '0.875rem', md: '1rem' },
  fontWeight: 'bold',
  lineHeight: { base: '1.2rem', md: '1.375.rem' },
}

function iconSize(w: number, p: number): SystemStyleObject {
  return {
    w: `${w}px`,
    h: `${w}px`,
    p: `${p}px`,
  }
}

export const sizes: Record<string, SystemStyleObject> = {
  xs: {
    ...baseText,
    h: '32px',
    padding: '8px 20px',
  },
  sm: {
    ...baseText,
    h: '40px',
    padding: { base: '6px 16px', md: '8px 20px' },
  },
  md: {
    ...baseText,
    h: '48px',
    padding: '13px 24px',
  },
  iconXS: iconSize(24, 4),
  iconSM: iconSize(32, 6),
  iconMD: iconSize(40, 8),
  iconLG: iconSize(48, 10),
  iconXL: iconSize(56, 12),
  icon2XL: iconSize(64, 14),
  icon3XL: iconSize(72, 16),
}

const baseButton = {
  border: 0,
  outline: 0,
  width: 'fit-content',
  _focus: {
    outline: 0,
    boxShadow: 0,
  },
}

const primary: SystemStyleObject = {
  ...baseButton,
  bg: 'green',
  color: 'white',
  borderRadius: '2px',
  _active: {
    bg: 'active',
  },
  _focusVisible: {
    outlineOffset: '-4px',
    outlineColor: 'white',
    outlineWidth: '2px',
    outlineStyle: 'solid',
  },
  _hover: {
    bg: 'active',
    color: 'white',
  },
  _disabled: {
    bg: 'disabled',
    opacity: 1,
  },
}

const primaryW: SystemStyleObject = {
  ...baseButton,
  bg: 'white',
  color: 'green',
  borderRadius: '2px',
  _active: {
    bg: 'active',
  },
  _focusVisible: {
    outlineOffset: '-4px',
    outlineColor: 'white',
    outlineWidth: '2px',
    outlineStyle: 'solid',
  },
  _hover: {
    bg: 'green',
    color: 'white',
  },
  _disabled: {
    bg: 'disabled',
    opacity: 1,
    color: 'white',
  },
}

const secondary: SystemStyleObject = {
  ...baseButton,
  bg: 'transparent',
  color: 'green',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: 'green',
  borderRadius: '2px',
  _active: {
    bg: 'transparent',
    borderColor: 'active',
    color: 'active',
  },
  _hover: {
    bg: 'transparent',
    borderColor: 'active',
    color: 'active',
  },
  _focusVisible: {
    outlineOffset: '-6px',
    outlineColor: 'green',
    outlineWidth: '2px',
    outlineStyle: 'solid',
  },
  _disabled: {
    borderColor: 'disabled',
    color: 'disabled',
    opacity: 1,
  },
}

export const tertiary: SystemStyleObject = {
  ...baseButton,
  bg: 'transparent',
  color: 'green',
  borderStyle: 'solid',
  borderWidth: '0',
  borderColor: 'transparent',
  _active: {
    bg: 'transparent',
    borderColor: 'transparent',
    color: 'active',
  },
  _hover: {
    bg: 'transparent',
    borderColor: 'transparent',
    color: 'active',
  },
  _focusVisible: {
    outlineOffset: '-6px',
    outlineColor: 'green',
    outlineWidth: '2px',
    outlineStyle: 'solid',
  },
  _disabled: {
    borderColor: 'transparent',
    color: 'disabled',
    opacity: 1,
  },
}

const iconG: SystemStyleObject = {
  ...primary,
  _hover: {
    bg: 'white_hover',
  },
  _active: {
    bg: 'white_hover',
  },
  _focusVisible: {
    outlineOffset: '-6px',
    outlineColor: 'white',
    outlineWidth: '2px',
    outlineStyle: 'solid',
  },
}

const iconW: SystemStyleObject = {
  ...baseButton,
  bg: 'white',
  color: 'green',
  border: 0,
  _active: {
    bg: 'active',
  },
  _hover: {
    bg: 'active',
  },
  _focusVisible: {
    outlineOffset: '-6px',
    outlineColor: 'green',
    outlineWidth: '2px',
    outlineStyle: 'solid',
  },
  _disabled: {
    color: 'white',
    bg: 'disabled',
    opacity: 1,
  },
}

export const Button = {
  sizes,
  variants: {
    primary,
    primaryW,
    secondary,
    tertiary,
    iconG,
    iconW,
    ghost: {
      border: null,
      outline: null,
      boxShadow: 'none',
      bg: null,
      fontWeight: null,
      borderRadius: null,
      minWidth: null,
      w: null,
      _active: {
        bg: null,
      },
      _hover: {
        bg: null,
      },
      _focus: {
        outline: null,
        boxShadow: null,
      },
    },
  },
  defaultProps: {
    size: '',
    variant: '',
    colorScheme: '',
  },
}
